html {
  font-size: 10px;
}

body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 10px;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1.4rem;
}

span {
  font-size: 1.4rem;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  @include respond-above(xs) {
    padding: 0 20px;
  }
  @include respond-above(sm) {
    padding: 0 30px;
  }
  @include respond-above(md) {
    // padding: 0 40px;
  }

  @media (min-width: 1280px) {
    max-width: 1200px;
  }

  @media (min-width: 1600px) {
    max-width: 1440px;
  }
}

@media (min-width:760px) and (max-width:790px) {
  .container{
    padding: 5px 0px  !important;
  }
}

@media (min-width:980px) and (max-width:997px) {
  .container{
    padding: 0px  !important;
  }
}

.content {
  padding: 10px 0;

  @include respond-above(xs) {
    padding: 20px 0;
  }
  @include respond-above(sm) {
    padding: 30px 0;
  }
}

.ant-table td {
  white-space: nowrap;
}

.ant-table-body {
  overflow: auto !important;
}

.ant-card {
  box-shadow: 0 2px 10px -4px rgba(38, 78, 99, 0.32);
}

.hide-val-message .ant-form-explain {
  display: none;
}

.ant-btn {
  border: 2px solid rgba(129, 143, 167, 0.17);
  border-radius: 0.6rem;
  box-shadow: 0 3px 20px -8px rgba(0, 0, 0, 0.16), 0 3px 20px -8px rgba(0, 0, 0, 0.23);
  text-transform: uppercase;

  &.ant-btn-round {
    border-radius: 3.2rem;
  }

  &.ant-btn-circle {
    border-radius: 50%;
  }

  &.ant-btn-circle-outline {
    border-radius: 50%;
  }

  &.ant-btn-link {
    box-shadow: none;
    border: none;
  }
}

.ant-btn-group {
  >.ant-btn {
    border-width: 1px;
  }
}

.ant-checkbox-inner {
  background-color: #d8d8d8;
}

.ant-input,
.ant-select {
  @extend .box-shadow;
}

.ant-select-lg {
  height: 5rem;

  >.ant-select-selection--single {
    height: 5rem;

    >.ant-select-selection__rendered {
      height: 5rem;
      line-height: 5rem;
    }
  }
}

.ant-input-lg {
  height: 5rem;
}

.ant-typography {
  font-size: 1.4rem;
}

.ant-modal-content {
  border-radius: 1rem;

  .ant-modal-header {
    border-radius: 1rem 1rem 0 0;
    border-bottom: none;

    >.ant-modal-title {
      text-align: center;
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }
}

.ant-layout-content {
  >.container {
    padding-top: 3rem;
  }
}

.ant-card-head-title {
  white-space: unset;
}

.two-rows-label .ant-form-item-label {
  padding: 4px 0 !important;

  label {
    white-space: normal;
    margin-right: 12px;
    display: inline-block;
    width: 70%;
    line-height: 15px;

    &:after {
      content: ':' !important;
    }

    &.ant-form-item-required:before {
      position: absolute;
      left: -7px;
    }
  }
}

.smtpConfForm .ant-form-item-children {
  display: flex;
}

.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
  display: none;
}

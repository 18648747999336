.btn {
  &--secondary {
    color: #fff;
    background-color: $secondary-color;
    border-color: darken($secondary-color, 10);

    &:hover,
    &:focus {
      color: #fff;
      background-color: lighten($secondary-color, 3);
      border-color: lighten($secondary-color, 3);
    }

    &:active {
      background-color: darken($secondary-color, 10);
    }
  }

  &--round {
    font-weight: bold;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  &--linkedin {
    color: #fff;
    background-color: #0e76a8;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: darken($color: #0e76a8, $amount: 5%);
    }
  }

  &--linkedin {
    color: #fff;
    background-color: #0e76a8;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: darken($color: #0e76a8, $amount: 5%);
    }
  }

  &--link {
    text-transform: none;
    padding: 0;
    background-color: transparent;
    color: $primary-color;
    border: none;
    font-size: 1.4rem;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: $primary-color;
      background-color: transparent;
    }
  }
}

.dashboard-tile {
  text-align: center;
  cursor: pointer;

  &__icon {
    font-size: 5rem;
    color: $primary-color;
  }

  &__title {
    display: block;
    font-size: 2rem;
  }
}

.float-label-input {
  font-size: 1rem;
  background: #fff;
  border: 0.2rem solid #ebeef4;
  border-radius: 0.6rem;
  position: relative;
  color: $text-secondary;

  &--focus,
  &--dirty {
    color: #000;
  }

  &--disabled {
    color: $text-secondary;
  }

  &--collapse-top {
    border-radius: 0.6rem 0.6rem 0 0;
    box-shadow: 0 2px 14px -4px rgba(38, 78, 99, 0.32);
  }

  &--collapse-center {
    border-top: 0;
    border-radius: 0;
  }

  &--collapse-bottom {
    border-top: 0;
    border-radius: 0 0 0.6rem 0.6rem;
    margin-bottom: 3rem;
    box-shadow: 0 2px 14px -4px rgba(38, 78, 99, 0.32);
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 2.5rem;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
    transition: top 0.3s ease 0s;

    &--with-icon {
      left: 5rem;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 1.75rem;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
    transition: top 0.3s ease 0s;
    font-size: 1.6rem;
  }

  &--dirty &__label,
  &--focus &__label {
    top: 33%;
  }

  &__label-text {
    font-size: 1.4rem;
    transition: font-size 0.3s ease 0s, color 0.3s ease 0s;
  }

  &--dirty &__label-text,
  &--focus &__label-text {
    font-size: 1.2rem;
  }

  &__input {
    font-family: inherit;
    font-size: 1.4rem;
    color: inherit;
    border: none;
    background: none;
    outline: 0;
    height: 60px;
    padding: 0;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    transition: color 0.3s ease 0s;
    padding: 2.6rem 2.5rem 1.2rem;

    &--with-icon {
      padding: 2.6rem 5rem 1.2rem;
    }
  }

  &--disabled &__input {
    color: $text-secondary;
    -webkit-text-fill-color: $text-secondary;
    opacity: 1 !important;
  }

  &__toggle-pass {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #a5a5a5;
  }
}

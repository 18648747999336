.import-contacts {
  padding: 2rem 3rem;
  background-color: #fff;
  box-shadow: 0 2px 20px -3px rgba(54, 56, 65, 0.2);
  border-radius: 2rem;

  @include respond-above(sm) {
    padding: 3.5rem 5rem;
    width: 47.4rem;
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: bold;
  }

  &__btn {
    width: 7rem;
    height: 7rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.23);

    > svg {
      max-width: 100%;
    }
  }
}

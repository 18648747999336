.column-list {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.2rem;
  overflow-y: auto;
  box-shadow: 3px 2px 9px -6px rgba(0, 0, 0, 0.22);

  > .ant-list {
    background-color: $background-primary;
  }

  .ant-list-items {
    padding: 0.5rem 1rem;
  }

  .ant-list-item:last-of-type {
    margin-bottom: 1rem;
  }
}

.header {
  @include box-shadow(2);
  height: auto;
  padding: 0;

  &__menu {
    background-color: transparent;
    border-bottom: none;
    text-align: center;

    > .ant-menu-submenu {
      border-bottom: 0.6rem solid transparent;

      &:hover {
        color: #fff !important;
        border-bottom: 0.6rem solid #fff;
      }
    }

    .ant-menu-submenu-selected,
    .ant-menu-submenu-title:hover {
      color: #fff !important;
    }
  }

  &__menu > &__menu-item {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.6rem;
    border-bottom: 0.6rem solid transparent;
    transition: border-bottom 0.25s, color 0.25s;

    &.ant-menu-item-selected,
    &:hover {
      color: #fff !important;
      border-bottom: 0.6rem solid #fff;
    }
  }

  &__search {
    > .ant-input {
      background: rgba(0, 0, 0, 0.1);
      border: none;
      padding-left: 3.2rem;
      padding-right: 0.4rem;
    }

    > .ant-input-suffix {
      left: 1.2rem;
      right: inherit;
    }
  }
}

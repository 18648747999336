.dot {
  display: inline-block;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 0.75rem;

  &--online {
    background-color: #7be56c;
  }

  &--offline {
    background-color: #e56c6c;
  }
}

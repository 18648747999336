.searched-candidate {
  @extend .box-shadow;
  background-color: #fff;
  border-radius: 0.6rem;
  margin-bottom: 2rem;

  &.ant-list-item {
    padding: 1rem;
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 0;
  }

  .ant-list-item-meta {
    margin-bottom: 1rem;

    @include respond-above(sm) {
      margin-bottom: 0;
    }

    > .ant-list-item-meta-avatar {
      position: relative;

      > .ant-avatar {
        line-height: 6rem;
        > .anticon {
          font-size: 2.4rem;
        }
      }
    }

    > .ant-list-item-meta-content {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }

  &__linkedin-logo {
    width: 1.6rem;
    height: auto;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
  }

  &__name {
    display: block;
    margin-bottom: 0.2rem;
  }

  &__job-title {
    display: block;
    line-height: 1.7rem;
    color: $text-secondary;
    margin-bottom: 0.5rem;
  }

  &__company-name {
    display: block;
    line-height: 1.7rem;
    line-height: 1.7rem;
    color: $text-secondary;
    font-style: italic;
    margin-bottom: 0;
  }
}

.job-alert {
  background-color: transparent;
  margin-bottom: 1rem;

  > .ant-alert-message {
    font-weight: bold;
    font-size: 1.6rem;
    color: $text-dark;
  }

  > .ant-alert-description {
    font-size: 1.4rem;
    color: $text-secondary;
  }

  > .ant-alert-icon {
    top: 50%;
    transform: translateY(-50%);
  }
}

.dashboard-recent-job {
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  border: 0.1rem solid lightgrey;
  border-radius: 0.3rem;

  &__title {
    &:before {
      content: '';
      left: -0.9rem;
      position: relative;
      border-left: 0.3rem solid blue;
    }

    font-weight: bold;
    font-size: 1.4rem;
  }

  &__description {
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  &__date {
    font-size: 1.2rem;
    color: $text-secondary;
  }
}

.view-candidates {
  padding-top: 1rem;

  @include respond-above(sm) {
    padding-top: 3rem;
  }

  @include respond-above(md) {
    padding-top: 4rem;
  }

  &__title {
    text-align: center;
    font-size: 2.6rem;
    line-height: 3.2rem;
    position: relative;
  }

  &__job-title {
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-weight: bold;
    color: $primary-color;
  }

  &__description {
    font-size: 1.4rem;
    white-space: pre-line;
    line-height: 2rem;
    margin-bottom: 5.5rem;
  }

  &__radio-buttons {
    &.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).view-candidates__radio--accept {
      background-color: #6dc250;
      border-color: #6dc250;
    }

    &.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled).view-candidates__radio--reject {
      background-color: #ee6761;
      border-color: #ee6761;
    }
  }

  &__client-text {
    background-color: #fff;
    padding: 3rem;

    img {
      max-width: 30rem;
    }
  }
}

.job-offer {
  height: 100%;

  &__header {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: #fff;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-weight: bold;
  }

  &__body {
    .ant-tabs-bar--bordered-bottom {
      .ant-tabs-bar {
        border-bottom: 1px solid #e8e8e8;
      }
    }

    .ant-tabs-bar {
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      border-bottom: 0;
      background-color: #fff;
      margin-bottom: 0;

      .ant-tabs-tab {
        text-transform: uppercase;
        color: $text-secondary;
        font-weight: bold;

        &-active,
        &:hover {
          color: $text-dark;
        }
      }

      .ant-tabs-ink-bar {
        height: 0.4rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }
    }
  }

  &__description {
    margin-top: 2.7rem;

    .ant-list-item-meta-title {
      font-weight: bold;
      color: $secondary-color;
      font-size: 1.6rem;
    }

    .ant-list-item-meta-description {
      font-size: 1.4rem;
      color: $text-secondary;
    }
  }

  &__description-title {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: $text-secondary;
  }

  &__description-content {
    white-space: pre-line;
    overflow-y: auto;
    max-height: 52rem;
    padding-right: 1.5rem;
  }

  &__candidates {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding-left: 1rem;

    .ant-card-body {
      padding: 0;
    }
  }

  &__candidates-header {
    padding: 1rem;
    margin-bottom: 2.7rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__candidates-tag-check-all {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__candidates-tag {
    padding: 0.4rem 4rem;
    border-radius: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    min-width: 10rem;
    text-align: center;

    &--new {
      background-color: #7394ff;
    }

    &--rejected {
      background-color: #ff5b5b;
    }

    &--sent {
      background-color: #863fff;
    }

    &--accepted {
      background-color: #4bc539;
    }
  }

  &__candidates-export-list-item {
    @extend .box-shadow;
    background-color: #f9f9f9;
    padding: 1rem !important;
    display: block;
    border-radius: 0.6rem;
    position: relative;
    margin-bottom: 2rem;
    transition: all 0.3s;

    .ant-list-item-meta-avatar {
      margin-right: 1rem;
    }

    .ant-list-item-meta {
      width: 92%;
      margin-bottom: 1rem;
    }

    .ant-list-item-meta-content {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }

  &__candidates-list-item {
    @extend .box-shadow;
    background-color: #fff;
    padding: 1rem !important;
    display: block;
    border-radius: 0.6rem;
    position: relative;
    margin-bottom: 2rem;
    transition: all 0.3s;

    &--selected,
    &:hover {
      background-color: $secondary-color;

      .job-offer__candidates-list-item-title {
        color: #fff;
      }

      .job-offer__candidates-list-item-time {
        color: #fff;
      }

      .job-offer__candidates-list-item-job {
        color: #fff;
      }

      .job-offer__candidates-list-item-company {
        color: #fff;
      }
    }

    .ant-list-item-meta-avatar {
      margin-right: 1rem;
    }

    .ant-list-item-meta {
      width: 92%;
      margin-bottom: 1rem;
    }

    .ant-list-item-meta-content {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }

  &__candidates-list-badge {
    position: absolute;
    top: -1rem;
    left: -1rem;
  }

  &__candidates-list-item-title {
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1.9rem;
    font-size: 1.6rem;

    &.ant-typography {
      display: block;
      margin-bottom: 0.6rem;
    }

    > span {
      font-size: 1.6rem;
    }
  }

  &__candidates-list-item-time {
    color: $text-secondary;
    line-height: 1.7rem;

    &.ant-typography {
      display: block;
    }

    .anticon {
      font-size: 1rem;
      margin-right: 0.2rem;
      position: relative;
      top: -0.1rem;
    }
  }

  &__candidates-list-item-view-btn {
    border: none;
    box-shadow: none;
  }

  &__candidates-list-item-checkbox {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  &__candidates-list-item-job {
    line-height: 1.7rem;
    color: $text-secondary;

    &.ant-typography {
      margin-bottom: 0.5rem;
    }
  }

  &__candidates-list-item-company {
    line-height: 1.7rem;
    color: $text-secondary;
    font-style: italic;

    &.ant-typography {
      margin-bottom: 0;
    }
  }

  &__candidates-list-item-icon-info {
    position: absolute;
    bottom: 0.8rem;
    Ho > sup {
      top: -0.9rem;
      font-size: 0.9rem;
    }

    &--comment {
      right: 3rem;

      > sup {
        color: #ffb400;
      }
    }

    &--recommendation {
      right: 0.45rem;

      > sup {
        color: #7394ff;
      }
    }
  }

  &__surveys {
    margin-top: 2.7rem;
  }
}

.view-campaign {
    display: flex;
    margin-bottom: 25px;
    position: relative;
    word-wrap: break-word;
}

.view-campaign>div:first-child {
    min-width: 160px;
    font-weight: 600;
}

.view-campaign>div:last-child {
    word-break: break-word;
}

.view-campaign:after {
    content: "";
    background-color: $primary-color;
    position: absolute;
    width: 2px;
    height: calc(100% + 30px);
    left: 145px;
    display: block;
}

.campaign-tab {
    display: flex;
}
.responsive-word {
    width: 30px;
}

@media (max-width : 768px) {
    .view-campaign {
        display: block;

        * {
            text-align: left !important;
            padding-left: 0 !important;
        }
    }

    .view-campaign:after {
        content: "";
        display: none;
    }

    .campaign-tab {
        display: block;
    }
}
@media (max-width : 1000px) {
    .view-campaign {
        display: block;

        * {
            text-align: left !important;
            padding-left: 0 !important;
        }
    }

    .view-campaign:after {
        content: "";
        display: none;
    }

    .campaign-tab {
        display: flex;
    }
}

@media (max-width: 1024px) {
    .view-campaign {
        .view-campaign>div:last-child {
            word-break: normal;
        }
    }
    .campaign-tab {
        display: block;
    }
}
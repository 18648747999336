.jobs-new {
  &__header {
    background-color: #fff;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    > .ant-menu-horizontal {
      border-bottom: none;
    }
  }

  &__header-title {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &__menu-item {
    text-transform: uppercase;
    font-weight: bold;

    &.ant-menu-item {
      border-bottom-width: 0.5rem;

      &:hover {
        border-bottom-width: 0.5rem;
      }
    }
  }

  &__content {
    padding: 1.5rem;
  }

  &__editor {
    background-color: #fff;
    @extend .box-shadow;
    line-height: 2rem;
    min-height: 40rem;

    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid $text-secondary;
    }
    .ql-container.ql-snow {
      border: none;
    }
  }

  &__btn {
    text-transform: uppercase;
    color: $text-dark-2;
    font-weight: bold;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: transparent;
    border: none;
    box-shadow: none;

    &.ant-btn-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &--primary {
      color: $primary-color;
    }

    &--selected {
      color: $text-secondary;

      &:hover,
      &:focus,
      &:active {
        color: $text-secondary;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    &.ant-btn-primary:not(:disabled) {
      color: #fff;
      background-color: $primary-color;
    }
  }

  &__select-add {
    background-color: $primary-color;
    color: #fff;
    transition: all 0.25s;

    &.ant-select-dropdown-menu-item-selected {
      background-color: $primary-color;

      &:hover,
      &:active,
      &:focus {
        background-color: $primary-color;
      }
    }

    &.ant-select-dropdown-menu-item-active {
      background-color: $primary-color;

      &:hover,
      &:active,
      &:focus {
        background-color: $primary-color;
      }
    }
  }

  &__search-contact {
    margin-bottom: 2rem;

    > .ant-input {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__list {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

  &__list-item {
    box-shadow: 0 3px 14px -2px rgba(0, 0, 0, 0.23);
    margin-bottom: 2rem;
    background-color: #fff;

    .ant-list-item-meta-title {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .ant-list-item-meta {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .ant-list-item-meta-description {
      color: $text-secondary;
      line-height: 1.7rem;
    }

    .ant-list-item-action {
      margin-left: 0;

      .jobs-new__btn--primary {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    &--selected {
      background-color: $secondary-color;

      &.ant-list-item {
        border-color: $secondary-color;
      }

      .ant-list-item-meta-title,
      .ant-list-item-meta-description,
      .jobs-new__btn--selected {
        color: #fff;
      }
    }
  }
}

.tile {
  height: 8rem;
  cursor: pointer;
  transition: background-color 0.25s;
  width: 100%;
  position: relative;

  &.cmpai {
    height: auto;

    &.tile__job--disabled {
      cursor: auto;
    }

    .tile__campaigns__title {
      line-height: normal;
    }
  }

  .ant-card-body {
    padding: 1rem 1.5rem 1rem 2rem;
  }

  &__job {
    $parent: &;

    &--active,
    &:hover {
      color: #fff;
      background-color: $primary-color;

      #{$parent}__title,
      #{$parent}__company,
      #{$parent}__date,
      #{$parent}__location {
        color: #fff;
      }

      #{$parent}__bookmark--empty {
        background-image: url('../../assets/icon-bookmark-white.svg');

        &:hover {
          background-image: url('../../assets/icon-bookmarked.svg');
        }
      }
    }

    &__candidates-count {
      position: absolute;
      top: -1rem;
      right: -1rem;
      z-index: 1;
    }

    &__title {
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 0.3rem;
      font-weight: bold;
      color: #424a57;
      line-height: 1.9rem;
      transition: color 0.25s;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__status {
      color: #fff;
      position: absolute;
      top: 0.5rem;
      right: 1rem;

      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.6rem;
      font-size: 1.2rem;

      &--draft {
        background-color: gray;
      }

      &--open {
        background-color: #7be56c;
      }

      &--archived {
        background-color: #e56c6c;
      }
    }

    &__bookmark {
      width: 1.3rem;
      height: 1.6rem;
      background: transparent center center no-repeat;
      background-size: contain;
      margin-left: auto;
      transition: background-image 0.25s;

      &--empty {
        background-image: url('../../assets/icon-bookmark.svg');
      }

      &--filled,
      &:hover {
        background-image: url('../../assets/icon-bookmarked.svg');
      }
    }

    &__company {
      display: block;
      margin-bottom: 0.6rem;
      color: #b1b1b1;
      line-height: 1.7rem;
      transition: color 0.25s;
    }

    &__date {
      display: block;
      font-size: 1.2rem;
      color: #b1b1b1;
      line-height: 1.5rem;
      transition: color 0.25s;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__location {
      display: block;
      font-size: 1.2rem;
      color: #b1b1b1;
      line-height: 1.5rem;
      transition: color 0.25s;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__survey {
    $parent: &;

    &--active,
    &:hover {
      color: #fff;
      background-color: $primary-color;

      #{$parent}__title,
      #{$parent}__questions-count {
        color: #fff;
      }
    }

    &__title {
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 0.3rem;
      font-weight: bold;
      color: #424a57;
      line-height: 1.9rem;
      transition: color 0.25s;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__questions-count {
      display: block;
      margin-bottom: 0.6rem;
      color: #b1b1b1;
      line-height: 1.7rem;
      transition: color 0.25s;
    }
  }

  &__campaigns {
    $parent: &;

    &--active,
    &:hover {
      color: #fff;
      background-color: $primary-color;

      #{$parent}__title,
      #{$parent}__company,
      #{$parent}__date,
      #{$parent}__location {
        color: #fff;
      }

      #{$parent}__bookmark--empty {
        background-image: url('../../assets/icon-bookmark-white.svg');

        &:hover {
          background-image: url('../../assets/icon-bookmarked.svg');
        }
      }
    }

    &__title {
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 0.3rem;
      font-weight: bold;
      color: #424a57;
      line-height: 1.9rem;
      transition: color 0.25s;
      max-width: 72%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__headerstatus {
      color: #fff;
      position: inherit;
      margin: 5px;
      top: 0.5rem;
      right: 1rem;

      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.6rem;
      font-size: 1.1rem;

      &--draft {
        background-color: gray;
      }

      &--scheduled {
        background-color: #5F9EA0;
      }

      &--started {
        background-color: #34b523;
      }

      &--archived {
        background-color: #e56c6c;
      }

      &--paused {
        background-color: #e56ccf;
      }

      &--stopped {
        background-color: #ea3d3d;
      }

      &--completed {
        // background-color: #2ffa06;
        background-color: #068156;
      }
    }

    &__status {
      color: #fff;
      position: absolute;
      top: 0.5rem;
      right: 1rem;

      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.6rem;
      font-size: 1.1rem;

      &--draft {
        background-color: gray;
      }

      &--scheduled {
        background-color: #5F9EA0;
      }

      &--started {
        background-color: #34b523;
      }

      &--archived {
        background-color: #e56c6c;
      }

      &--paused {
        background-color: #e56ccf;
      }

      &--stopped {
        background-color: #ea3d3d;
      }

      &--completed {
        // background-color: #2ffa06;
        background-color: #068156;
      }
    }

    &__bookmark {
      width: 1.3rem;
      height: 1.6rem;
      background: transparent center center no-repeat;
      background-size: contain;
      margin-left: auto;
      transition: background-image 0.25s;

      &--empty {
        background-image: url('../../assets/icon-bookmark.svg');
      }

      &--filled,
      &:hover {
        background-image: url('../../assets/icon-bookmarked.svg');
      }
    }
  }
}
.security-layout {
  &__content {
    padding: 3rem;

    @include respond-above(md) {
      padding: 4rem;
    }

    @include respond-above(lg) {
      padding: 5rem;
    }
  }
}

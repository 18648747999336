.job-email-collapse {
  background-color: transparent;
  border: none;

  &__item {
    @extend .box-shadow;
    margin-bottom: 1.5rem;

    &.ant-collapse-item {
      border-bottom: none;

      > .ant-collapse-header {
        background-color: #fff;

        > .ant-collapse-arrow {
          background-color: $text-dark-2;
          border-radius: 50%;
          color: #fff;
          padding: 1rem;
        }
      }

      > .ant-collapse-content {
        border-top: none;
        background-color: transparent;
      }
    }
  }

  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-right: 6rem;
  }
  &__subject {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: bold;
    color: $text-dark;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 1rem;
  }

  &__subject-help {
    color: $text-secondary;
  }
}

.suggest-candidate {
  padding-top: 1rem;

  @include respond-above(sm) {
    padding-top: 3rem;
  }

  @include respond-above(md) {
    padding-top: 4rem;
  }

  &__title {
    text-align: center;
    font-size: 2.6rem;
    line-height: 3.2rem;
    position: relative;
  }

  &__job-title {
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-weight: bold;
    color: $primary-color;
  }

  &__description {
    font-size: 1.4rem;
    white-space: pre-line;
    line-height: 2rem;
    margin-bottom: 5.5rem;
  }

  &__message {
    p {
      margin-bottom: 0;
    }
  }

  &__back-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    box-shadow: none;
  }
}

.upload-photo {
  background-color: #fff;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.23);
  border-radius: 0.6rem;
  height: calc(100% - 2.4rem);
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &--no-image {
    .ant-upload {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .ant-upload {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 1.5rem;
      }
    }
  }

  &__placeholder-icon {
    width: 5rem;
    color: $text-dark-2;
    margin-bottom: 1.25rem;
  }

  &__placeholder-text {
    font-weight: bold;
    bottom: 0.75rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $text-dark-2;
  }

  .ant-avatar {
    width: 10rem;
    height: 10rem;
  }
}

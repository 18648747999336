.ql-align-center {
  text-align: center;
}

.help-red {
  .ant-form-explain {
    color: #f5222d;
  }
}

.contact-by-cmpg {

  .i-facebook,
  .i-linkedin {
    display: none;
  }
}

.help-smaller {
  .ant-form-explain {
    font-size: 1.2rem;
  }
}

.ant-btn-group:not(.ant-btn-group-sm) {
  .ant-btn-icon-only[disabled]:last-of-type {
    top: 0px;
    border-width: 1px;
  }
}
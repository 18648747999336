.view-summary {
    display: flex;
    margin-bottom: 25px;
    position: relative;
    word-wrap: break-word;
}

.view-summary>div:first-child {
    min-width: 152px;
    font-weight: 600;
}

.view-summary>div:last-child {
    word-break: break-word;
    margin-left: 10px;
}

.view-summary:after {
    content: "";
    background-color: $primary-color;
    position: absolute;
    width: 2px;
    height: calc(100% + 30px);
    left: 145px;
    display: block;
}

.job-message-tab {
    display: flex;
}

.tab_title {
    text-align: center;
    text-decoration-line: underline;
    color: #ffb400;
}

@media (max-width : 768px) {
    .view-summary {
        display: block;

        * {
            text-align: left !important;
            padding-left: 0 !important;
        }
    }

    .view-summary:after {
        content: "";
        display: none;
    }

    .job-message-tab {
        display: block;
    }
}

@media (max-width : 1000px) {
    .view-summary {
        display: block;

        * {
            text-align: left !important;
            padding-left: 0 !important;
        }
    }

    .view-summary:after {
        content: "";
        display: none;
    }

    .job-message-tab {
        display: flex;
    }
}

@media (max-width: 1024px) {
    .view-summary {
        .view-summary>div:last-child {
            word-break: normal;
        }
    }
    .view-summary>div:first-child {
        min-width: 140px;
        // font-weight: 600;
        word-break: normal;
    }

    .job-message-tab {
        display: block;
    }

    .view-summary:after {
        content: "";
        left: 125px;
    }
}

@media (min-width: 600px) {

    .job-message-tab {
        display: flex;
    }
}
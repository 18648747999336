.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $text-secondary;
}

.box-shadow {
  box-shadow: 0 1px 10px -4px rgba(0, 0, 0, 0.12), 0 1px 10px -4px rgba(0, 0, 0, 0.24);
}

.text-uppercase {
  text-transform: uppercase;
}

.contact-list {
  &__item {
    @extend .box-shadow;
    position: relative;
    background-color: #fff;
    border-radius: 0.6rem;
    padding: 1rem;
    margin-bottom: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond-above(xl) {
      flex-wrap: initial;
    }

    &--smaller-gap {
      margin-bottom: 0.5rem;
    }

    .ant-list-item-meta-title {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 0;
    }

    .ant-list-item-meta {
      max-width: 100%;
      margin-bottom: 1rem;

      @include respond-above(xl) {
        max-width: 50%;
      }

      > .ant-list-item-meta-avatar {
        > .ant-avatar {
          line-height: 6rem;
          > .anticon {
            font-size: 2.4rem;
          }
        }
      }

      > .ant-list-item-meta-content {
        flex-wrap: nowrap;
        overflow: hidden;
      }
    }

    .ant-list-item-action {
      text-align: right;
      flex: 1;
      min-width: 16rem;
      flex-basis: 100%;
    }
  }

  &__item-title {
    &.ant-typography {
      margin-bottom: 0.2rem;
    }
  }

  &__item-job {
    line-height: 1.7rem;
    color: $text-secondary;

    &.ant-typography {
      margin-bottom: 0.5rem;
    }
  }

  &__item-company {
    line-height: 1.7rem;
    color: $text-secondary;
    font-style: italic;

    &.ant-typography {
      margin-bottom: 0.5rem;
    }
  }

  &__action {
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      border: none;
      color: #fff;
    }

    &--accept {
      background-color: #6dc250;

      &:hover,
      &:focus,
      &:active {
        background-color: #6dc250;
      }
    }

    &--reject {
      background-color: #ee6761;

      &:hover,
      &:focus,
      &:active {
        background-color: #ee6761;
      }
    }

    &--comment {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__view-btn {
    border: none;
    box-shadow: none;
  }

  &.ant-list-grid .ant-col > .ant-list-item {
    padding: 1rem;
    display: flex;
  }

  &__recommendation-icon {
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
}

.contact-tile {
  cursor: pointer;
  text-align: center;
  padding: 1.5rem;
  height: 19.5rem;

  &__actions {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-add-to-list {
    font-size: 1.6rem;
  }

  &__avatar {
    width: 6rem;
    height: 6rem;
    line-height: 6rem;
    margin-bottom: 0.6rem;
  }

  &__name {
    display: block;
    font-weight: bold;
    line-height: 1.9rem;
    font-size: 1.6rem;
    margin-bottom: 0.4rem;

    &.ant-typography {
      margin-bottom: 0.5rem;
    }
  }

  &__job {
    display: block;
    color: $text-secondary;
    line-height: 1.7rem;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;

    &.ant-typography {
      margin-bottom: 0.5rem;
    }
  }

  &__company {
    display: block;
    color: $text-secondary;
    line-height: 1.7rem;
    font-size: 1.4rem;
    font-style: italic;
    margin-bottom: 0;
  }

  &__contact-list-item {
    > .ant-checkbox-wrapper {
      width: 100%;
    }
  }
}

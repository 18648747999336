.tag {
  cursor: pointer;
  background-color: #fff;
  color: $secondary-color;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 1.25rem;
  @extend .box-shadow;
  margin-bottom: 0.5rem;

  &--primary {
    background-color: $primary-color;
    color: #fff;
  }

  &--secondary {
    background-color: $secondary-color;
    color: #fff;
  }
}

.avatar-upload {
  text-align: center;
  margin-bottom: 1rem;

  .ant-upload {
    position: relative;

    > .ant-upload {
      display: block;
    }
  }

  &__img {
    height: 10rem;
    width: 10rem;
    line-height: 10rem;
    cursor: pointer;
    background-color: $text-secondary;

    &.ant-avatar {
      font-size: 3.5rem;
    }

    &--no-pointer {
      cursor: auto;
    }
  }

  &__btn {
    position: absolute;
    color: #fff;
    border: 0;
    z-index: 1;

    &--edit {
      top: 0;
      right: 0;
      background-color: $secondary-color;

      &:hover,
      &:active,
      &:focus {
        background-color: lighten($secondary-color, 20);
        color: #fff;
      }
    }

    &--remove {
      right: 0;
      bottom: 0;

      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }
    }
  }
}

.add-new-button {
  margin-bottom: 1.5rem;
  height: 4.5rem;
  text-transform: uppercase;
  color: $primary-color;
  transition: background-color 0.3s;
  padding: 0;
  display: flex;
  justify-content: space-between;

  &__text {
    padding: 0 1rem;
    margin: auto auto;
  }

  &__icon-bg {
    width: 9rem;
    height: 4.5rem;
    background-color: $primary-color;
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
}
